<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title-item">个人中心</div>
      <div class="user-container">
        <pcUserHeader pageKey="people" />
        <div class="tg-item" v-if="userInfo.FzLevel >= 50">
          <div class="title-item">推广链接</div>
          <div class="qrcode-item">
            <div class="qrcode img-cover" ref="qrcode"></div>
            <div class="detail">
              <div class="path">{{ url }}</div>
              <div class="btn" @click="handleCopy(url)">复制推广链接</div>
            </div>
          </div>
        </div>
        <div class="people-item">
          <div class="nav-item">
            <div class="item" :class="Type == 1 ? 'active' : ''" @click="handleTypeChange(1)">会员</div>
            <div class="item" :class="Type == 2 ? 'active' : ''" @click="handleTypeChange(2)">站长</div>
          </div>
          <div class="table-item">
            <div class="table-head">
              <div class="td">头像</div>
              <div class="td">昵称</div>
              <div class="td">ID</div>
              <!-- <div class="td">电话</div> -->
              <!-- <div class="td">注册时间</div> -->
              <div v-if="Type == 1" class="td">会员等级</div>
              <div v-else class="td">分站等级</div>
              <!-- <div class="td">消费金额</div> -->
            </div>
            <div v-for="(item, index) in peopleList" :key="index" class="table-body">
              <div class="td">
                <div class="header img-cover">
                    <img v-if="item.Header" :src="item.Header" />
                    <img v-else src="../../assets/images/default-header.png" />
                  </div>
              </div>
              <div class="td">{{ item.Nickname }}</div>
              <div class="td">{{ item.Id }}</div>
              <!-- <div class="td">{{ item.Phone }}</div> -->
              <!-- <div class="td">{{ $TOOL.dateFormat(item.AddTime * 1000, 'yyyy-MM-dd hh:mm') }}</div> -->
              <div v-if="Type == 1" class="td">{{ item.VName }}</div>
              <div v-else class="td">{{ item.FName }}</div>
              <!-- <div class="td">{{ item.Balance }}</div> -->
            </div>
            <div v-if="total > pageCount" class="pagination-item">
              <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcUserHeader from '@/components/pcUserHeader'

export default {
  name: "People",
  components: {
    pcPublicHeader,
    pcUserHeader
  },
  data() {
    return {
      url: '',
      Type: 1,
      peopleList: [],
      pageCount: 10,
      page: 1,
      total: 0,
      finish: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetWebsiteInfo()
    this.getPeopleList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleGetWebsiteInfo() {
      var res = await this.$API.substationsInfo.post()
      this.url = res.info.url && res.info.url[0] && res.info.url[0].Url
      this.handleSetQrcode()
    },
    handleSetQrcode() {
      if (this.$refs.qrcode) {
        new QRCode(this.$refs.qrcode, {
          text: this.url,
          width: 160,
          height: 160,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    handleTypeChange(type) {
      this.Type = type
      this.page = 1
      this.loading = false
      this.finish = false
      this.total = 0
      this.peopleList = []
      this.getPeopleList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.getPeopleList()
    },
    async getPeopleList() {
      const { loading, pageCount, page, Type } = this
      if (loading) {
        return
      }
      const params = {
        PageCount: pageCount, 
        Page: page,
        Type: Type
      }
      var res = await this.$API.peopleList.post(params)
      this.total = res.count
      this.peopleList = [
        ...res.info
      ]
      this.finish = res.info.length < pageCount
      this.loading = false
    },
  }
};
</script>

<style lang="less" scoped>
.page-title-item {
  padding: 30px 0 24px;
  line-height: 46px;
  color: #3d3d42;
  font-size: 32px;
  font-weight: bold;
}
.user-container {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}
.tg-item {
  padding: 30px 30px 0;
  .title-item {
    line-height: 28px;
    font-size: 18px;
    color: #3d3d42;
    font-weight: bold;
  }
  .qrcode-item {
    margin-top: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 30px;
    display: flex;
    align-items: center;
    .qrcode {
      width: 160px;
      height: 160px;
    }
    .detail {
      flex: 1;
      padding: 30px 0 0 30px;
      .path {
        height: 42px;
        line-height: 42px;
        padding: 0 20px;
        background-color: rgba(106, 95, 254, .12);
        border-radius: 4px;
        font-size: 14px;
        color: #333;
        font-weight: bold;
      }
      .btn {
        cursor: pointer;
        margin-top: 24px;
        width: 156px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        background-color: #6a5ffe;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
.people-item {
  padding-top: 30px;
  .nav-item {
    width: 100%;
    height: 64px;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .item {
      cursor: pointer;
      width: 124px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 8px 0 0;
      margin-right: 30px;
      font-size: 16px;
      color: #3d3d42;
      &.active,
      &:hover {
        font-weight: bold;
        color: #6a5ffe;
        background-color: #fff;
        box-shadow: 4px -4px 12px 0 rgba(0, 0, 0, .06);
      }
    }
  }
  .table-item {
    padding: 30px;
    .table-head {
      height: 46px;
      border: 1px solid #bebebe;
      background-color: #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .table-body {
      margin-bottom: 10px;
      height: 120px;
      background-color: #eff4ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        overflow: hidden;
      }
    }
    .td {
      flex: 1;
      color: #333;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
